/* Josh W. C. CSS Reset <3 https://www.joshwcomeau.com/css/custom-css-reset/
/* 1. Use a more-intuitive box-sizing model. */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* 2. Remove default margin */
* {
  margin: 0;
}
/* 3. Allow percentage-based heights in the application */
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/* 6. Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/* 7. Remove built-in form typography styles */
input,
button,
textarea,
select {
  font: inherit;
}
/* 8. Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/* 9. Create a root stacking context */
#root,
#__next {
  isolation: isolate;
}

/* Button CSS reset, https://css-tricks.com/overriding-default-button-styles/ */
button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button:hover,
button:focus {
  background: #0053ba;
}
button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}
button:active {
  transform: scale(0.99);
}
