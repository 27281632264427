body {
  --background-dark: #272838;
  --background-light: #f9f8f8;
  --accent-dark: #f3de8a;
  --accent-light: #7e7f9a;

  --knob-size: 25px;

  background-color: var(--background-dark);
  color: var(--background-light);
  font-family: Arial, Helvetica, sans-serif;

  user-select: none;
}

.logo {
  margin-right: auto;
}

nav {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: var(--background-dark);
  color: var(--accent-dark);
  box-shadow: 0 0 10px 5px #000000aa;
  border-bottom: 1px solid var(--accent-light);
  z-index: 1;
  position: relative;
}

nav .buttons-container {
  display: flex;
  gap: 8px;
}

@media (width <= 360px) {
  nav .buttons-container a:first-child {
    display: none;
  }
}

nav a {
  text-decoration: none;
  color: var(--accent-dark);
}

nav a:hover {
  text-decoration: underline;
  color: var(--random-colour);
  text-decoration-color: var(--random-colour);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px auto 48px auto;
  max-width: 800px;
  text-align: center;
  gap: 16px;
}

.avatar-container {
  align-self: center;
  justify-self: center;
}

.intro-container,
.intro-container > * {
  color: var(--accent-dark);
  white-space: pre-line;
}

.avatar-img {
  border-radius: 100%;
  width: 150px;
  border: 2px solid var(--accent-dark);
  box-shadow: var(--accent-light) 0 0 5px 5px;
}

@media (max-width: 650px) {
  .container {
    margin: 8px 8px;
  }

  .project-poster {
    width: 80%;
  }
}

.knob {
  touch-action: none;
  width: var(--knob-size);
  height: var(--knob-size);
}
.know:hover {
  cursor: pointer;
}
